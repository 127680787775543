<template>
  <!-- 机构端 - 分销管理 - 分销商城 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">分销管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">分销机构订单</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="商品名称" class="searchboxItem">
              <span class="itemLabel">商品名称:</span>
              <el-input
                v-model="retrievalData.tradeName"
                type="text"
                size="small"
                placeholder="请输入商品名称"
                clearable
              />
            </div>
            <!-- <div title="商品分类" class="searchboxItem">
              <span class="itemLabel">商品分类:</span>
              <el-cascader
                :options="tradeTypeData"
                v-model="retrievalData.tradeType"
                :props="miniProps"
                size="small"
                clearable
              ></el-cascader>
            </div> -->
            <div title="购买机构" class="searchboxItem">
              <span class="itemLabel">购买机构:</span>
              <el-select
                size="small"
                v-model="retrievalData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
            >
                <el-option
                v-for="item in CompanyList"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
                ></el-option>
            </el-select>
            </div>
            <!-- <div title="支付方式" class="searchboxItem">
              <span class="itemLabel">支付方式:</span>
              <el-select
                clearable
                size="small"
                v-model="retrievalData.keyState"
                placeholder="请选择支付方式"
              >
                <el-option
                  v-for="item in openList1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div> -->
            <div title="下单时间" class="searchboxItem">
              <span class="itemLabel">下单时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="retrievalData.startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
          <!-- <div class="searchbox" style="padding-top: 10px">         
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div> -->
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @sort-change="changeSort"
              :default-sort="{ prop: 'auditTime', order: 'ascending' }"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="订单编号"
                align="left"
                show-overflow-tooltip
                prop="orderSn"
                min-width="150"
              />
              <el-table-column
                label="商品名称"
                align="left"
                show-overflow-tooltip
                prop="productName"
                min-width="200"
              />
              <el-table-column
                label="商品单价（元）"
                align="left"
                show-overflow-tooltip
                prop="productPrice"
                min-width="100"
              />
              <el-table-column
                label="购买数量"
                align="left"
                show-overflow-tooltip
                prop="productCnt"
                min-width="100"
              />
              <el-table-column
                label="实付金额（元）"
                align="left"
                show-overflow-tooltip
                prop="paymentMoney"
                min-width="100"
              />
              <el-table-column
                label="购买机构"
                align="left"
                show-overflow-tooltip
                prop="orderCompanyName"
                min-width="200"
              />
              <el-table-column
                label="支付方式"
                align="left"
                :show-overflow-tooltip="true"
                min-width="100"
              ><span>微信</span>
              </el-table-column>
              <el-table-column
                label="下单时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="200"
              />
              <el-table-column
                label="已兑换数量"
                align="left"
                show-overflow-tooltip
                prop="changeNum"
                min-width="100"
              />
              <el-table-column
                label="剩余兑换数量"
                align="left"
                show-overflow-tooltip
                min-width="100"
              ><template slot-scope="scope">{{
                  scope.row.productCnt - scope.row.changeNum
                }}</template>
              </el-table-column>
              <!-- <el-table-column
                label="实付金额"
                align="left"
                :show-overflow-tooltip="true"
                min-width="120"
              >
              没有这个字段
                <template slot-scope="scope">{{
                  scope.row.marketPrice || "--"
                }}</template>
              </el-table-column> -->
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "distributionShoppingMall",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        tradeName: "", // 商品名称
        compId:"", // 购买机构
        startTime: [] // 下单时间
        // tradeType: "", // 商品分类
      },
      // 商品分类下拉数据
      tradeTypeData: [],
      // 购买机构的下拉数据
      CompanyList: [],
      // 商品分类下拉数据 - 对应数据类型
      miniProps: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
    };
  },
  computed: {},
  methods: {
    // 获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { hiddenState: "10" },
        3000,
        true,
        2
      ).then((ret) => {
        this.tradeTypeData = this.delchild(ret.data);
      });
    },
    // 处理-获取课程分类数组数据
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    // 输入三个组 - 获取购买机构的下拉数据
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
              console.log(this.CompanyList)
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取列表数据
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        productName: this.retrievalData.tradeName,
        compId: this.retrievalData.compId,
      };
      if (this.retrievalData.startTime) {
        params.startTime = this.retrievalData.startTime[0];
        params.endTime = this.retrievalData.startTime[1];
      }
      this.doFetch(
        {
          url: "/order/drp-order-main/selectYWList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
  },

  watch: {},
  created() {
    this.getcourseTypeArrData();
  },
};
</script>
<style lang="less">
.activreCss {
  border-color: cornflowerblue !important;
}
.stairList {
  position: relative;
  width: 24%;
  float: left;
  margin-right: 1%;
  padding: 4px 0;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  p {
    line-height: 26px;
    padding-left: 26px;
    span {
      font-size: 18px;
      color: cornflowerblue;
      padding-left: 6px;
    }
  }
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  width: 150px;
  margin: auto;
  img {
    background-color: #999; //设置白色背景色
  }
}
</style>